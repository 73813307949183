import React, { useEffect, useState } from 'react';
import { useUser } from '../context/userContext';
import BoostRank from './BoostRank';

const UserRanking = () => {
  const { leaderBoard, activeUserRank, fullName, balance } = useUser();
  const [initials, setInitials] = useState(null);

  // Функция для получения первых двух букв имени
  const getInitials = (username) => {
    return username.slice(0, 2).toUpperCase(); // Берем первые две буквы имени
  };

  // Функция для сохранения инициалов в localStorage
  const saveInitialsToLocalStorage = (initials) => {
    localStorage.setItem('userInitials', initials);
  };

  useEffect(() => {
    // Проверяем, есть ли инициал в localStorage
    const storedInitials = localStorage.getItem('userInitials');
    if (storedInitials) {
      setInitials(storedInitials);
    } else {
      // Если нет, генерируем и сохраняем
      const generatedInitials = getInitials(fullName);
      setInitials(generatedInitials);
      saveInitialsToLocalStorage(generatedInitials);
    }
  }, [fullName]); // Перегенерировать инициал при изменении fullName

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  const shortenName = (name) => {
    if (name.length > 16) {
      return name.substring(0, 16) + '...';
    }
    return name;
  };

  // Custom images for top 5 users
  const rankImages = [
    '/1st.webp', '/2nd.webp', '/3rd.webp', '/4th.webp', '/5th.webp',
    '/6th.webp', '/7th.webp', '/8th.webp', '/9th.webp', '/10th.webp',
  ];

  return (
    <>
      <div className="w-full flex flex-col space-y-2 justify-center items-center relative">
        <div className="bg-[#181818] py-2 px-3 flex flex-col font-medium w-full rounded-[8px]">
          <h2 className="text-[13px] text-secondary font-semibold">Your Rank</h2>
          <div className="w-full rounded-[16px] py-2 flex items-center justify-between space-x-3">
            <div className="w-fit">
              <div className={`flex items-center justify-center h-[38px] w-[38px] rounded-full p-1 bg-[#181818]`}> {/* Используем один фиксированный цвет */}
                <span className="font-semibold text-[14px]">{initials}</span>
              </div>
            </div>
            <div className="flex h-full flex-1 flex-col justify-center relative">
              <div className="flex w-full flex-col justify-between h-full space-y-[2px]">
                <h1 className="text-[14px] text-nowrap line-clamp-1 font-medium">
                  {shortenName(fullName)}
                </h1>
                <span className="flex items-center gap-1 flex-1 text-[12px]">
                  <img src="/stars2.svg" alt="dvf" className="w-[10px]" />
                  <span className="text-[12px] text-nowrap font-medium">
                    {formatNumber(balance)}
                  </span>
                </span>
              </div>
            </div>
            <div className="w-fit flex items-center justify-end flex-wrap text-[14px] relative px-1">
              <button className="font-semibold ease-in duration-200">
                #{activeUserRank}
              </button>
            </div>
          </div>
        </div>

        <div className="w-full relative bg-[#000000] rounded-[8px] flex flex-col space-y-2">
          <BoostRank />
        </div>
      </div>

      <div className="w-full flex flex-col space-y-3 pt-3">
        {leaderBoard
          .sort((a, b) => b.balance - a.balance) // Sort users by balance in descending order
          .slice(0, 100) // Ограничиваем отображение до 100 пользователей
          .map((user, index) => (
            <div
              key={user.id}
              className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-3"
            >
              <div className="w-fit">
                <div className={`flex items-center justify-center h-[42px] w-[42px] rounded-full p-1 bg-[#181818]`}> {/* Один фиксированный цвет */}
                  <span className="font-semibold text-[14px]">{getInitials(user.fullName)}</span>
                </div>
              </div>
              <div className="flex h-full flex-1 flex-col justify-center relative">
                <div className="flex w-full flex-col justify-between h-full space-y-[2px]">
                  <h1 className="text-[14px] text-nowrap line-clamp-1 font-medium">
                    {shortenName(user.fullName)}
                  </h1>
                  <span className="flex items-center gap-1 flex-1 text-[12px]">
                    <img src="/stars2.svg" alt="dvf" className="w-[10px]" />
                    <span className="text-[12px] text-nowrap font-medium">
                      {formatNumber(user.balance)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="w-fit flex items-center justify-end flex-wrap text-[14px] relative px-4">
                {index < 3 ? (
                  <img
                    src={rankImages[index]} // Display the custom image for the user rank
                    alt={`Rank ${index + 1}`}
                    className="w-[24px] h-auto"
                  />
                ) : (
                  <button className="font-semibold ease-in duration-200">
                    #{index + 1}
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default UserRanking;
